import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private localStorage: Storage | null = null;

  private initialized: { subject: Subject<any>; observable: Observable<any> };

  constructor(private storage: Storage) {
    const subject = new Subject();
    const observable = subject.asObservable();
    this.initialized = {subject, observable};
    this.init().then(() => this.initialized.subject.next(this.localStorage));
  }

  public async init() {
    console.log('Init Storage');
    this.localStorage = await this.storage.create();
  }

  public get(key: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (this.localStorage) {
        const value = await this.localStorage?.get(key);
        resolve(value);
      } else {
        this.initialized.observable.subscribe(async () => {
          const value = await this.localStorage?.get(key);
          resolve(value);
        });
      }
    });
  }

  public set(key: string, value: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (this.localStorage) {
        const response = await this.localStorage?.set(key, value);
        resolve(response);
      } else {
        this.initialized.observable.subscribe(async () => {
          const response = await this.localStorage?.set(key, value);
          resolve(response);
        });
      }
    });
  }

  public remove(key: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (this.localStorage) {
        const response = await this.localStorage?.remove(key);
        resolve(response);
      } else {
        this.initialized.observable.subscribe(async () => {
          const response = await this.localStorage?.remove(key);
          resolve(response);
        });
      }
    });
  }

  public keys(): Promise<string[]> {
    return new Promise(async (resolve, reject) => {
      if (this.localStorage) {
        const keys = await this.localStorage?.keys();
        resolve(keys);
      } else {
        this.initialized.observable.subscribe(async () => {
          const keys = await this.localStorage?.keys();
          resolve(keys);
        });
      }
    });
  }
}
