import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {HttpClient} from '@angular/common/http';
import {UserService} from './user.service';
import {Events} from './events.service';
import {ConnectionService} from './connection.service';
import {UtilService} from './util.service';
import {firstValueFrom, interval} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {TrackingService} from './tracking.service';
import {SettingsService} from './settings.service';

export interface Job {
    jobId: string;
    startTime: string;
    endTime: string;
    executionServer: string;
    submitted: string;
    timeout: string;
    error: string;
    expanded?: boolean;
    results?: Result[];
}

export interface Result {
    imageId: string;
    feedback: {
        userApproved: boolean,
        comment: string,
        imageId: string
    };
    readable: boolean;
    AuftragsID: string;
    result: object;
    maxPerc: number;
    yPredict: number;
    yPredictClassDec: string;
    finalClass: string;
    errorMessages: string;
    images: string;
}

@Injectable({
    providedIn: 'root'
})
export class JobsService {

    sub: any;

    constructor(
        private connectionService: ConnectionService,
        private events: Events,
        private settings: SettingsService,
        private storage: StorageService,
        private httpClient: HttpClient,
        private translate: TranslateService,
        private userService: UserService,
        private tracker: TrackingService,
        private utilService: UtilService) {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    async getJobs(userId: string): Promise<Job[]> {
        this.tracker.trackEvent('jobs', 'get all jobs');
        const urlJobs = (await this.settings.get('url-base')) + 'jobs/me';
        const jobs: Job[] = (await firstValueFrom(this.httpClient.get(`${urlJobs}?userid=${userId}`))) as Job[];
        return jobs.filter(job => !!job); // remove null entries
    }

    async getResults(jobId: string): Promise<Result[]> {
        this.tracker.trackEvent('jobs', 'get results');
        const urlResults = (await this.settings.get('url-base')) + 'results/';
        return (await firstValueFrom(this.httpClient.get(urlResults + jobId))) as Result[];
    }

    async startJob(jobInfo) {
        console.log('will start job', jobInfo);
        const currentJob = {
            id: jobInfo.sessionId,
            image: jobInfo.url,
            executionModel: 'torch_monocultures',
            classified: null
        };
        await this.storage.set('current-job', currentJob);

        this.tracker.trackEvent('jobs', 'start job');
        const urlJobs = (await this.settings.get('url-base')) + 'jobs/';
        return firstValueFrom(this.httpClient.post(urlJobs + currentJob.id + '?model=torch_monocultures', { }));
    }

    async trackJob(jobId) {
        // querying every 10 seconds
        this.sub = interval(10000).subscribe(async () => {
            const connected = this.connectionService.getConnectionStatus();
            if (!connected) {
                // this.utilService.showNoConnectionAlert();
                // TODO show alert and unsubscribe or keep querying?
            } else {
                const results = await this.getResults(jobId)
                console.log(results);
                // if (result['class'] || result['finalClass'] || result['error']) {
                if (results && results.length > 0) {
                    await this.sub.unsubscribe();
                    await this.storage.remove('current-job');
                    this.events.publish('jobs:status-change', results[results.length - 1]);
                }
            }
        });
    }

    async stopTrackingJob() {
        // could be stopped before subscription was set
        await this.storage.remove('current-job');
        if (this.sub) {
            await this.sub.unsubscribe();
        }
    }

    async restoreJob() {
        // if (job) {
        //     this.events.publish('jobs:status-change', job);
        // }
        return this.storage.get('current-job');
    }

    async sendFeedback(result: Result) {
        const connected = this.connectionService.getConnectionStatus();
        if (!connected) {
            await this.utilService.showNoConnectionAlert();
            return 'no connection';
        } else {
            if (result.hasOwnProperty('feedback')) {
                result.feedback.imageId = result.imageId;
                this.tracker.trackEvent('jobs', 'send feedback');
                const urlFeedback = (await this.settings.get('url-base')) + 'results/feedback/';
                return firstValueFrom(this.httpClient.post(urlFeedback + result.imageId, result.feedback));
            }
        }
    }

    async generateCSV(results: Result[]) {
        const me = this;

        // header line
        let csv = await firstValueFrom(this.translate.get('services.jobs.csv-header'));
        let allClasses = []
        results.forEach(result => {
            allClasses = allClasses.concat(Object.keys(result.result));
        });
        allClasses = [...new Set(allClasses)];
        const translatedClasses: string[] = await Promise.all(allClasses.map(async (e) => {
            return await firstValueFrom(me.translate.get('crops.' + e));
          }));

        for (const entry of translatedClasses) {
            const confidence = await firstValueFrom(this.translate.get('services.jobs.confidence'));
            csv += `${confidence} ${entry};`
        }
        // remove last semicolon
        csv = csv.substr(0, csv.length - 1) + '\n';

        // data for each image
        results.forEach(result => {
            // file name
            csv += result.images.split('/').slice(-1) + ';';
            csv += result.finalClass + ';';
            csv += Math.round(result.result[result.finalClass] * 1000) / 1000 + ';';
            allClasses.forEach(entry => {
                if (result.result.hasOwnProperty(entry)) {
                    csv += Math.round(result.result[entry] * 1000) / 1000 + ';'
                } else {
                    csv += 'NA;'
                }
            });
            // remove last semicolon
            csv = csv.substr(0, csv.length - 1) + '\n';
        });
        return csv;
    }
}
