import {Component} from '@angular/core';
import {NavParams, PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-list-popover',
  templateUrl: './list-popover.component.html',
  styleUrls: ['./list-popover.component.scss'],
})
export class ListPopoverComponent {

  items: {id: string, title: string}[];
  popoverCtrl: PopoverController;

  constructor(
      navParams: NavParams
  ) {
    this.items = navParams.get('items');
    this.popoverCtrl = navParams.get('popoverCtrl');
  }
}
