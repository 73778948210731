import {Injectable} from '@angular/core';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    constructor(
        private alertCtrl: AlertController,
        private translate: TranslateService
    ) {
    }

    static fallbackCopyTextToClipboard(text) {
        const textArea = document.createElement('textarea');
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    }

    static async copyTextToClipboard(text) {
        if (!navigator.clipboard) {
            UtilService.fallbackCopyTextToClipboard(text);
            return;
        }
        try {
            await navigator.clipboard.writeText(text);
            console.log('Async: Copying to clipboard was successful!');
        } catch (e) {
            console.error('Async: Could not copy text: ', e);
        }
    }

    static downloadTextFile(fileName: string, fileContent: string) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(fileContent));
        element.setAttribute('download', fileName);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    static generateId(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    async showErrorMsg(e: any) {
        const header = await firstValueFrom(this.translate.get('services.util.error-header'));
        const closeBtn = await firstValueFrom(this.translate.get('shared.btn-close'));
        let message;
        if (typeof e === 'string') {
            message = e;
        } else if (e.error && e.error.error_description) {
            message = e.error.error_description;
        } else if (e.error && e.error.message) {
            message = e.error.message;
        } else if (e.msg) {
            message = e.msg;
        } else if (e.message) {
            message = e.message;
        }
        const alert = await this.alertCtrl.create({
            header,
            message,
            buttons: [closeBtn]
        });
        await alert.present();
    }

    async showNoConnectionAlert() {
        const header = await firstValueFrom(this.translate.get('services.util.error-connection-header'));
        const message = await firstValueFrom(this.translate.get('services.util.error-connection-message'));
        const closeBtn = await firstValueFrom(this.translate.get('shared.btn-close'));
        const alert = await this.alertCtrl.create({
            header,
            message,
            buttons: [{
                text: closeBtn,
                role: 'cancel'
            }]
        });
        await alert.present();
    }

}
