import { Injectable } from '@angular/core';
import { Network, ConnectionStatus } from '@capacitor/network';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  connectionStatus: boolean;

  constructor() {
  }

  async setupSubscriptions() {
    Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
      this.connectionStatus = status.connected;
    });

    // set initial state
    this.connectionStatus = (await Network.getStatus()).connected;
  }

  getConnectionStatus() {
    return this.connectionStatus;
  }

}
