import {Component, Input} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'feedback-modal',
    templateUrl: 'feedback.modal.html'
})
export class FeedbackModalComponent {
    @Input() comment: string;
    @Input() modalCtrl: ModalController;

    data = {
        comment: '',
        userApproved: true,
        imageId: ''
    }
}
