import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from './storage.service';
import {Events} from './events.service';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    supportedLanguages = ['de', 'en'];
    settings: { key: string; value: any }[];

    constructor(
        private http: HttpClient,
        private events: Events,
        private translate: TranslateService,
        private storage: StorageService
    ) {
    }

    async loadConfig() {
        // const configLoaded = await this.get('config-loaded');
        // if (!configLoaded) {
          const data: any = await firstValueFrom(this.http.get('/assets/json/config.json'));
          if (data && data.urlBase) {
              await this.set('url-base', data.urlBase);
              console.log('config loaded');
          }
          await this.set('config-loaded', true);
        // }
    }

    /**
     * Set current language, optionally save in local storage
     * @param lang Language to be used in application
     * @param store Whether to save set language in local storage
     * @param silent When silent, no event is fired after changing the language
     */
    async setLanguage(lang: string, store: boolean = true, silent: boolean = false) {
        if (!lang || this.supportedLanguages.indexOf(lang) === -1) {
            lang = 'auto';
        }
        if (store) {
            // store used language
            await this.storage.set('set-language', lang);
        }
        if (lang === 'auto') {
            if (navigator.language) {
                const browserLang = navigator.language.split('-')[0];
                if (this.supportedLanguages.indexOf(browserLang) !== -1) {
                    lang = browserLang;
                } else {
                    // else use english
                    lang = 'en';
                }
            } else {
                // else use english
                lang = 'en';
            }
        }

        // tell translate service to use new language
        this.translate.use(lang);
        // finally set html element lang property
        document.documentElement.lang = lang;
        if (!silent) {
            this.events.publish('language:change', lang);
        }
    }

    async get(key: string): Promise<any> {
        if (!this.settings) {
          await this.restore();
        }
        const setting = this.settings.find(entry => entry.key === key);
        return setting?.value;
      }

      async set(key: string, value: any, overwrite: boolean = true) {
        if (!this.settings) {
          await this.restore();
        }
        const index = this.settings.findIndex(entry => entry.key === key);
        if (index === -1) {
          this.settings.push({key, value});
          await this.save();
        } else if (overwrite) {
          this.settings[index].value = value;
          await this.save();
        }
      }

      async remove(key: string) {
        if (!this.settings) {
          await this.restore();
        }
        const index = this.settings.findIndex(entry => entry.key === key);
        if (index !== -1) {
          this.settings.splice(index, 1);
          await this.save();
        }
      }

      async clear() {
        this.settings = [];
        await this.save();
      }

      private async restore() {
        const keys = await this.storage.keys();
        if (keys.indexOf('user-settings') !== -1) {
          this.settings = await this.storage.get('user-settings');
        } else {
          this.settings = [];
        }
      }

      private async save() {
        await this.storage.set('user-settings', this.settings);
      }
}
