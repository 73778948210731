import {Component, OnInit} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-info-privacy',
    templateUrl: './info-privacy.page.html',
    styleUrls: ['./info-privacy.page.scss'],
})
export class InfoPrivacyPage implements OnInit {

    language: string;
    modalCtrl: ModalController;

    constructor(
        navParams: NavParams,
        private translate: TranslateService
    ) {
        this.modalCtrl = navParams.get('modalCtrl');
    }

    ngOnInit() {
        this.language = this.translate.currentLang || this.translate.getDefaultLang();
    }
}
