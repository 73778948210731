// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-list {
  cursor: pointer;
  padding: 0;
}
ion-list ion-item:last-child {
  --border-style: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/list-popover/list-popover.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,UAAA;AACF;AACE;EACE,oBAAA;AACJ","sourcesContent":["ion-list {\n  cursor: pointer;\n  padding: 0;\n\n  ion-item:last-child {\n    --border-style: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
