import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AlertController, ToastController, ModalController} from '@ionic/angular';
import {firstValueFrom} from "rxjs";
import {InfoPrivacyPage} from '../pages/info/tabs/info-privacy/info-privacy.page';
import {MatomoTracker} from "ngx-matomo-client";

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(
    private alertCtrl: AlertController,
    private matomoTracker: MatomoTracker,
    private toastCtrl: ToastController,
    private translate: TranslateService,
    private modalCtrl: ModalController,
  ) {
  }

  async askForTrackingConsent(): Promise<boolean> {
    const header: string = await firstValueFrom(this.translate.get('app.cookie-preferences.header'));
    const message: string = await firstValueFrom(this.translate.get('app.cookie-preferences.message'));
    const btnAgree: string = await firstValueFrom(this.translate.get('shared.agree'));
    const btnDecline: string = await firstValueFrom(this.translate.get('shared.decline'));
    let consentGiven: boolean;
    /* Test if Do-Not-Track is enabled. */
    const dnt = (((window as any).doNotTrack && (window as any).doNotTrack === '1') ||
      (navigator.doNotTrack && (navigator.doNotTrack === 'yes' || navigator.doNotTrack === '1')) ||
      ((navigator as any).msDoNotTrack && (navigator as any).msDoNotTrack === '1'));
    if (dnt) {
      consentGiven = false;
      const dntFoundToast = await firstValueFrom(this.translate.get('app.cookie-preferences.dnt-toast'));
      let cssClass = 'dnt-toast';
      if (window.innerWidth >= 1280 && window.innerHeight < 830 || window.innerHeight < 730) {
        cssClass += ' slim-header';
      }
      const toast = await this.toastCtrl.create({
        message: dntFoundToast,
        duration: 3000,
        position: 'top',
        cssClass
      });
      await toast.present();
    } else {
      const alert = await this.alertCtrl.create({
        header,
        message,
        cssClass: 'cookie-dialog',
        backdropDismiss: false,
        buttons: [{
          text: btnDecline,
          role: 'deny'
        }, {
          text: btnAgree,
          role: 'consent'
        }]
      });
      await alert.present();
      const privacyButton = document.getElementById('privacy-button');
      privacyButton.onclick = () => this.showPrivacyModal();
      const detail = await alert.onDidDismiss();
      consentGiven = detail.role === 'consent';
    }
    return consentGiven;
  }

  async showPrivacyModal() {
    const component = InfoPrivacyPage;
      const modal = await this.modalCtrl.create({
        component, componentProps: {
              modalCtrl: this.modalCtrl
          }
      });
      await modal.present();
      await modal.onDidDismiss();
    }

  async toggleTracking(consent: boolean) {
    if (consent) {
      // init matomo
      this.matomoTracker.rememberConsentGiven();
      this.matomoTracker.rememberCookieConsentGiven();
    } else {
      // no matomo :-(
      this.matomoTracker.forgetConsentGiven();
      this.matomoTracker.forgetCookieConsentGiven();
    }
  }

  trackPageView(customTitle?: string) {
    this.matomoTracker.trackPageView(customTitle);
  }

  trackEvent(category: string, action: string, name?: string, value?: number) {
    this.matomoTracker.trackEvent(category, action, name, value);
  }
}
