import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ChartConfiguration, ChartDataset } from 'chart.js';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as exif from 'exif-js';
import { FeedbackModalComponent } from './feedback.modal';
import { JobsService, Result } from '../../services/jobs.service';
import { UtilService } from '../../services/util.service';
import Chart from 'chart.js/auto';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import {SettingsService} from '../../services/settings.service';

@Component({
    selector: 'details-modal',
    templateUrl: 'details.modal.html',
    styleUrls: ['./details.modal.scss'],
})
export class DetailsModalComponent {
    // Data passed in by componentProps
    @Input() result: Result;
    @Input() modalCtrl: ModalController;

    classesWiki: { class: string, wiki: string }[];
    imageData = {
        name: '',
        datetime: '',
        GPSLatitude: null,
        GPSLongitude: null,
        GPSDOP: ''
    };

    barChartConfig: ChartConfiguration = null;
    barChartData: ChartDataset[] = [
        { data: [0.5, 0.5, 0.5], label: this.translate.instant('components.job.modal-details.label-confidence-classes') }
    ];
    barChartColors: Array<any> = [{
        backgroundColor: this.randomColorGenerator,
        borderColor: 'rgba(0, 0, 0, 1)'
    }];

    imageUrl: string;
    initialUserFeedback = {
        userApproved: true,
        comment: '',
        imageId: ''
    };

    constructor(
        private alertCtrl: AlertController,
        private httpClient: HttpClient,
        private jobsService: JobsService,
        private settings: SettingsService,
        private translate: TranslateService,
        private util: UtilService
    ) {
    }

    // Change detector for the element in the DOM.
    @ViewChild('chartCanvas') set function(element: ElementRef) {
        if (element) {
            // The canvas element does not initially exist in the DOM.
            // Only when the statistics area is displayed the element is added to the DOM.
            // At this moment, this function is called. Then the height diagram can be created.
            if (this.barChartData) {
                this.createBarChart(element);
            }
        }
    }

    async ionViewDidEnter() {
        console.log('will present result', this.result);

        const urlImageRetrieve = (await this.settings.get('url-base')) + 'images/';
        this.imageUrl = urlImageRetrieve + this.result.images + '?size=700';

        this.getWiki().subscribe(data => {
            this.classesWiki = data;
        });

        this.initialUserFeedback.imageId = this.result.imageId;
        // this.initialUserFeedback = {
        //     comment: this.result.comment,
        //     userApproved: this.result.userApproved
        // };
    }

    getMetaData(image): Promise<any> {
        return new Promise((resolve, reject) => {
            exif.getData(image, function () {
                const allMetaData = exif.getAllTags(this);
                console.log('metadata');
                console.log(allMetaData);
                resolve(allMetaData);
            });
        });
    }

    async loaded(e) {
        const index = this.result.images.lastIndexOf('/') + 1;
        this.imageData.name = this.result.images.substr(index);

        const metaData = await this.getMetaData(e.target);

        this.imageData.datetime = metaData.DateTimeOriginal ? metaData.DateTimeOriginal : null;

        if (metaData.GPSLatitude) {
            this.imageData.GPSLatitude = Math.round((metaData.GPSLatitude[0] +
                metaData.GPSLatitude[1] / 60 + metaData.GPSLatitude[2] / 3600) * 1000) / 1000;
        }

        if (metaData.GPSLongitude) {
            this.imageData.GPSLongitude = Math.round((metaData.GPSLongitude[0] +
                metaData.GPSLongitude[1] / 60 + metaData.GPSLongitude[2] / 3600) * 1000) / 1000;
        }

        this.imageData.GPSDOP = metaData.GPSDOP ? metaData.GPSDOP : null;
    }

    getWiki(): Observable<any> {
        return this.httpClient.get('assets/json/classes_wiki.json');
    }

    chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
        // console.log(event, active);
    }

    async randomColorGenerator() {
        const r = Math.floor(Math.random() * 256);
        const g = (Math.floor(Math.random() * 156) + 50 + r) % 256;
        const b = (Math.floor(Math.random() * 156) + 50 + g) % 256;
        // Collect all to a string
        return 'rgba(' + r + ',' + g + ',' + b + ', 0.4)';
    }

    async createBarChart(element: ElementRef) {
        const sortableValues = [], labels = [], values = [];
        const resultObject = this.result.result;
        const colours = [];
        for (const crop in resultObject) {
            if (resultObject.hasOwnProperty(crop)) {
                sortableValues.push([this.translate.instant('crops.' + crop), resultObject[crop]])
            }
        }
        sortableValues.sort((a, b) => {
            return b[1] - a[1];
        });

        for (let index = 0; index < 3; index++) {
            if (sortableValues[index][1] !== 0) {
                labels.push(sortableValues[index][0]);
                values.push(Math.round(sortableValues[index][1] * 100) / 100);
                colours.push(await this.randomColorGenerator());
            }
        }

        const chartLabel = await firstValueFrom(this.translate.get('components.job.modal-details.label-confidence-classes'));

        if (!this.barChartConfig) {
            this.barChartConfig = {
                type: 'bar',
                data: {
                    labels,
                    datasets: [{
                        label: chartLabel,
                        data: values,
                        backgroundColor: colours
                    }]
                },
                options: {
                    indexAxis: 'y',
                    scales: {
                        x: {
                            max: 1.0
                        },
                        y: {}
                    },
                    // plugins: {
                    //     datalabels: {
                    //         align: 'end',
                    //         anchor: 'start',
                    //         offset: 0,
                    //         clamp: true,
                    //     }
                    // }
                },

            };
            console.log('config', this.barChartConfig);
        }
        if (this.barChartConfig) {
            // tslint:disable-next-line:no-unused-expression
            new Chart(element.nativeElement, this.barChartConfig);
        }
    }

    async leaveFeedback() {
        console.log('feedback', this.result);
        const comment = this.result.feedback && this.result.feedback.comment ? this.result.feedback.comment : '';
        const modal = await this.modalCtrl.create({
            component: FeedbackModalComponent,
            componentProps: {
                comment,
                modalCtrl: this.modalCtrl
            }
        });
        await modal.present();
        const data = await modal.onDidDismiss();
        if (data.data) {
            this.result.feedback = data.data;
            this.result.feedback.imageId = this.result.imageId;
            try {
                const response: any = await this.jobsService.sendFeedback(this.result);
                console.log(response);
                if (response && response.error && response.error.length > 0) {
                    this.result.feedback = this.initialUserFeedback;
                    await this.util.showErrorMsg(response.error);
                } else if (response === 'no connection') {
                    this.result.feedback = this.initialUserFeedback;
                } else {
                    this.initialUserFeedback = this.result.feedback;
                    await this.showFeedbackSuccessInfo();
                }
            } catch (e) {
                console.log(e);
                this.result.feedback = this.initialUserFeedback;
                await this.util.showErrorMsg(e);
            }
        }
    }

    private async showFeedbackSuccessInfo() {
        const header = await firstValueFrom(this.translate.get('components.job.modal-details.feedback-success.header'));
        const message = await firstValueFrom(this.translate.get('components.job.modal-details.feedback-success.message'));
        const btnClose = await firstValueFrom(this.translate.get('shared.btn-close'));

        const alert = await this.alertCtrl.create({
            header,
            message,
            buttons: [{
                text: btnClose,
                role: 'cancel',
                cssClass: 'primary'
            }]
        });
        await alert.present();
    }

    async downloadCSV() {
        const fileName = 'ca_klassifikation_' + this.imageData.name.split('.')[0] + '.csv';
        const csv = await this.jobsService.generateCSV([this.result]);

        UtilService.downloadTextFile(fileName, csv);
    }
}
