import {Component, OnInit} from '@angular/core';

import {MenuController, Platform, PopoverController} from '@ionic/angular';
import {Events} from './services/events.service';
import {User, UserService} from './services/user.service';
import {ConnectionService} from './services/connection.service';
import {ListPopoverComponent} from './components/list-popover/list-popover.component';
import {Router} from '@angular/router';

import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';

import {Capacitor, Plugins} from '@capacitor/core';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from './services/storage.service';
import {SettingsService} from './services/settings.service';

const {StatusBar} = Plugins;
import {TrackingService} from './services/tracking.service';

import { VERSION } from './constants';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

    user: User;

    constructor(
        private connectionService: ConnectionService,
        private events: Events,
        private menuCtrl: MenuController,
        private platform: Platform,
        private popoverCtrl: PopoverController,
        private router: Router,
        private settings: SettingsService,
        private storage: StorageService,
        private translate: TranslateService,
        private userService: UserService,
        private trackingService: TrackingService,
    ) {
    }

    async ngOnInit() {
        await this.platform.ready();
        if ((this.platform.is('android') || this.platform.is('ios')) && Capacitor.getPlatform() !== 'web') {
            // color has to be a hex string
            await StatusBar.setBackgroundColor({color: '#546e7a'});
        }
        console.log('App init. Version: ', VERSION);

        registerLocaleData(localeDe, 'de');
        await this.connectionService.setupSubscriptions();

        this.events.subscribe('user:login-change', (user) => {
            this.user = user;
            console.log(this.user);
        });
        await this.userService.restore();

        let consent = await this.settings.get('tracking-consent');
        if (consent === undefined) {
            consent = await this.trackingService.askForTrackingConsent();
            await this.settings.set('tracking-consent', consent);
        }
        await this.trackingService.toggleTracking(consent);
    }

    async showUserPopover(event: MouseEvent) {
        const popover = await this.popoverCtrl.create({
            component: ListPopoverComponent,
            componentProps: {
                items: [{
                    id: 'settings',
                    title: 'Benutzerprofil'
                }, {
                    id: 'logout',
                    title: 'Abmelden'
                }],
                popoverCtrl: this.popoverCtrl
            },
            event
        });
        popover.onDidDismiss().then(detail => this.onUserPopoverDismiss(detail.data.selected));
        await popover.present();
    }

    async onUserPopoverDismiss(selectedItem) {
        switch (selectedItem.id) {
            case 'settings':
                await this.router.navigate(['/settings']);
                break;
        }
        await this.menuCtrl.close();
    }
}
