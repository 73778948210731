import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StorageService} from './storage.service';
import {Events} from './events.service';
import {UtilService} from './util.service';
import {ConnectionService} from './connection.service';
import {TranslateService} from '@ngx-translate/core';
import {SettingsService} from './settings.service';
import { firstValueFrom } from 'rxjs';
import { TrackingService } from './tracking.service';

export interface User {
    username: string;
    email: string;
    active: boolean;
    role: 'ROLE_UNREGISTERED' | 'ROLE_REGISTERED';
    userId: string;
    created_on: string;
    login_on: string;
    language?: string;
}

@Injectable({
    providedIn: 'root'
})
export class UserService {

    restoringUser = false;
    restored = false;

    constructor(
        private connectionService: ConnectionService,
        private events: Events,
        private httpClient: HttpClient,
        private settings: SettingsService,
        private storage: StorageService,
        private translate: TranslateService,
        private utilService: UtilService,
        private tracker: TrackingService
    ) {
        this.events.subscribe('language:change', lang => this.setUserLanguage(lang));
    }

    async register(email?: string, username?: string): Promise<User> {
        let user;

        const body: any = {};
        if (email) {
            body.email = email;
        }
        if (username) {
            body.username = username;
        }
        const storedLang = await this.storage.get('set-language');
        if (storedLang === 'DE' || storedLang === 'de') {
            body.language = 'DE';
        } else if (storedLang === 'EN' || storedLang === 'en') {
            body.language = 'EN';
        }
        const connected = this.connectionService.getConnectionStatus();
        if (!connected) {
            await this.utilService.showNoConnectionAlert();
            return null;
        } else {
            try {
                this.tracker.trackEvent('user', 'register');
                const urlRegister = (await this.settings.get('url-base')) + 'users/register';
                user = (await firstValueFrom(this.httpClient.post(urlRegister, body))) as User;
            } catch (e) {
                console.log(e);
                await this.utilService.showErrorMsg(e);
                // todo check for 409 and offer to restore user account
            }
            return user;
        }
    }

    async createNewUser(showError, quiet): Promise<User> {
        if (showError) {
            const message = await firstValueFrom(this.translate.get('services.user.error-restore-user'));
            await this.utilService.showErrorMsg(message);
        }

        const user = await this.register();
        if (user) {
            await this.updateUser(user, quiet);
            return user;
        }
    }

    async restore(quiet: boolean = false): Promise<any> {
        console.log('restore');
        let user: User;
        if (!this.restored && !this.restoringUser) {
            this.restoringUser = true;
            const connected = this.connectionService.getConnectionStatus();
            user = await this.storage.get('user');
            if (user && user.userId) {
                if (connected) {
                    try {
                        this.tracker.trackEvent('user', 'restore account');
                        const urlMe = (await this.settings.get('url-base')) + 'users/me/';
                        user = (await firstValueFrom(this.httpClient.get(urlMe + user.userId))) as User;
                        if (user) {
                            if (!user.email) {
                                user.language = await this.storage.get('set-language') || 'auto';
                            }
                            await this.updateUser(user, true);
                        }
                    } catch (error) {
                        user = await this.createNewUser(true, quiet);
                    }
                }
                if (!quiet) {
                    this.events.publish('user:login-change', user);
                }
            } else {
                if (connected) {
                    user = await this.createNewUser(false, quiet);
                } else {
                    await this.utilService.showNoConnectionAlert();
                }
            }
            this.restoringUser = false;
            this.restored = true;
            return user;
        } else if (this.restored) {
            user = await this.storage.get('user');
            this.events.publish('user:login-change', user);
            return user;
        } else {
            return 'please wait';
        }
    }

    async restoreById(userId: string) {
        try {
            console.log('will restore by id');
            const urlMe = (await this.settings.get('url-base')) + 'users/me/';
            const user: User = (await firstValueFrom(this.httpClient.get(urlMe + userId))) as User;
            if (user) {
                if (!user.email) {
                    user.language = await this.storage.get('set-language') || 'auto';
                }
                await this.updateUser(user);
                return user;
            }
        } catch (e) {
            console.log(e);
            if (e.status === 404) {
                e.message = await firstValueFrom(this.translate.get('services.user.error-invalid-id'));
            }
            await this.utilService.showErrorMsg(e);
            return null;
        }
    }

    async deleteAccount(user?: User) {
        const restoredId = !!user;
        if (!user) {
            user = await this.storage.get('user');
        }
        const connected = this.connectionService.getConnectionStatus();
        if (!connected) {
            await this.utilService.showNoConnectionAlert();
        } else {
            try {
                console.log('will delete by id');
                this.tracker.trackEvent('user', 'delete account');
                const urlMe = (await this.settings.get('url-base')) + 'users/me/';
                const response = firstValueFrom(this.httpClient.delete(urlMe + user.userId));
                if (!restoredId) {
                    user = await this.register();
                    await this.updateUser(user);
                }
            } catch (e) {
                if (!restoredId) {
                    const message = await firstValueFrom(this.translate.get('services.user.error-delete-account'));
                    await this.utilService.showErrorMsg(message);
                }
            }
        }
    }

    async changeEmail(email: string) {
        const user = await this.storage.get('user');
        const connected = this.connectionService.getConnectionStatus();
        if (!connected) {
            await this.utilService.showNoConnectionAlert();
            return null;
        } else {
            try {
                console.log('will change email');
                this.tracker.trackEvent('user', 'set email');
                const urlMe = (await this.settings.get('url-base')) + 'users/me/';
                const response = await firstValueFrom(this.httpClient.put(urlMe + user.userId,{email}));
                await this.updateUser(response as User);
                return response;
            } catch (e) {
                if (e.status === 409) {
                    // await this.utilService.showErrorMsg('Die angegebene E-Mail-Adresse wird bereits verwendet.');
                    throw(e);
                } else {
                    const message = await firstValueFrom(this.translate.get('services.user.error-save-email'));
                    await this.utilService.showErrorMsg(message);
                }
                return null;
            }
        }
    }

    async setUserLanguage(lang: string) {
        const user = await this.storage.get('user');
        user.language = lang.toUpperCase();
        await this.storage.set('user', user);
        if (user.email) {
            console.log('will set user language');
            this.tracker.trackEvent('user', 'set language: ' + lang);
            const urlMe = (await this.settings.get('url-base')) + 'users/me/';
            await firstValueFrom(this.httpClient.put(urlMe + user.userId, user));
        }
    }

    async updateUser(user: User, quiet: boolean = false) {
        console.log('update user', quiet);
        await this.storage.set('user', user);
        if (user.language) {
            await this.settings.setLanguage(user.language.toLowerCase(), true, true);
        }
        if (!quiet) {
            this.events.publish('user:login-change', user);
        }
    }

    async logout() {
        const connected = this.connectionService.getConnectionStatus();
        if (!connected) {
            await this.utilService.showNoConnectionAlert();
            return null;
        } else {
            const user = await this.register();
            await this.updateUser(user);
        }
    }

    async restoreByEmail(email: string) {
        const connected = this.connectionService.getConnectionStatus();
        if (!connected) {
            await this.utilService.showNoConnectionAlert();
            return null;
        } else {
            try {
                this.tracker.trackEvent('user', 'restore by email');
                const urlRecover = (await this.settings.get('url-base')) + 'users/email/recover';
                return firstValueFrom(this.httpClient.post(urlRecover, {email}));
            } catch (e) {
                let message = "";
                if (e.status === 404 && e.error === 'Not Found') {
                    message = await firstValueFrom(this.translate.get('services.user.error-email-not-found'));
                } else {
                    message = await firstValueFrom(this.translate.get('services.user.error-restore-account'));
                }
                
                await this.utilService.showErrorMsg(message);
            }
        }
    }
}
