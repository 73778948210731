import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy, NavParams} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import {IonicStorageModule} from '@ionic/storage-angular';
import {ListPopoverComponent} from './components/list-popover/list-popover.component';

import {DetailsModalComponent} from './components/job/details.modal';
import {FeedbackModalComponent} from './components/job/feedback.modal';
import {FormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MatomoConsentMode, MatomoModule, MatomoRouterModule} from 'ngx-matomo-client';
import {provideCharts, withDefaultRegisterables} from 'ng2-charts';
import {SettingsService} from './services/settings.service';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

export function initializeApp(settings: SettingsService) {
    return (): Promise<any> => {
        return settings.loadConfig();
    }
}

@NgModule({
    declarations: [AppComponent, ListPopoverComponent, DetailsModalComponent, FeedbackModalComponent],
    imports: [
        AppRoutingModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        IonicModule.forRoot({
            innerHTMLTemplatesEnabled: true
        }),
        IonicStorageModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        MatomoModule.forRoot({
            siteId: 12,
            trackerUrl: 'https://stats.apps.eftas.com/matomo.php',
            scriptUrl: 'https://stats.apps.eftas.com/matomo.js',
            requireConsent: MatomoConsentMode.TRACKING
          }),
          MatomoRouterModule
    ],
    exports: [
        DetailsModalComponent
    ],
    providers: [
        NavParams,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        provideCharts(withDefaultRegisterables()),
        SettingsService,
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [SettingsService], multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
